<template>
  <section :class="$style.container" class="flex flex-column">
    <tabs v-model="tab" :class="$style.tabs" data-testid="tabs" />
    <div :class="$style.content" class="flex flex-column">
      <loader v-if="isLoading" center :animated="false" />
      <template v-else>
        <div
          v-show="(!polls.length && isTabVoting) || (!tests.length && isTabTests)"
          class="flex flex-center"
          :class="$style.content__empty"
          data-testid="emptyListMessage"
        >
          {{ emptyListMessage }}
        </div>
        <speaker-polls
          v-if="isTabVoting && polls.length"
          :polls="polls"
          :results="results"
          :active-poll-id="activePollId"
          :active-results-id="activeResultsId"
          :active-about-ws="activeAboutWs"
          data-testid="polls"
          @get-results="getResultsHandler"
          @toggle-poll="togglePoll"
          @toggle-results="toggleResultsHandler"
          @back="backHandler"
        />
        <div v-if="isTabTests && tests.length" :class="$style.tests">
          <div v-for="group in tests" :key="group.group_id" :class="$style.group">
            <div v-if="group.group_name" :class="$style.groupName">
              <span class="group_name">{{ group.group_name }}</span>
            </div>
            <div class="tests">
              <speaker-poll-card
                v-for="test in group.not_started"
                :key="test.id"
                test
                :item="test"
                :class="$style.tests__item"
                :active-poll-id="activeTestId"
                :data-testid="'test' + test.id"
                :active-about-ws="activeAboutWs"
                @toggle-test="toggleTestHandler(test.id)"
              />
              <speaker-poll-card
                v-for="test in group.finished"
                :key="test.id"
                test
                :item="test"
                :class="$style.tests__item"
                :active-poll-id="activeTestId"
                :data-testid="'test' + test.id"
                :active-about-ws="activeAboutWs"
                @toggle-test="toggleTestHandler(test.id)"
              />
              <speaker-poll-card
                v-for="test in group.started"
                :key="test.id"
                test
                :item="test"
                :class="$style.tests__item"
                :active-poll-id="activeTestId"
                :data-testid="'test' + test.id"
                :active-about-ws="activeAboutWs"
                @toggle-test="toggleTestHandler(test.id)"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { polls, pollsTest, room, socket, speakerPolls } from "@/store/modules/store.namespaces";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import {
  GET_POLLS,
  GET_SPEAKER_TEST,
  SHOW_QUIZ_RESULT,
  START_SPEAKER_TEST,
  TOGGLE_QUIZ,
} from "@/store/modules/speaker/modules/polls/action-types";
import { SHOW_POLL_SCORE } from "@/store/modules/polls/action-types";
import {
  ACTIVE_POLL_ID,
  ACTIVE_RESULTS_ID,
  ACTIVE_TEST_ID,
} from "@/store/modules/common/getter-types";
import { SET_POLLCHAIN_SCORE } from "@/store/modules/polls/mutation-types";
import { test } from "@/constants/polls/polls-module-types";
import { ACTIVE_ABOUT_WS, TESTS } from "@/store/modules/speaker/modules/getter-types";

import Tabs from "@/components/speaker/testsAndPolls/SpeakerTestAndPollsTabs";
import SpeakerPolls from "@/components/speaker/testsAndPolls/SpeakerPolls";
import Loader from "@/components/common/elements/Loader";
import SpeakerPollCard from "@/components/speaker/testsAndPolls/SpeakerPollCard";
import { SET_ROOMINFO_TEST_ID } from "@/store/modules/common/mutation-types";

const LOAD_POLLS_INTERVAL_MS = 5000;

/**
 * Страница для отображения списков тестов или опросов
 *
 * @vue-data {string} tab - текущая вкладка
 * @vue-data {boolean} isLoading - идет загрузка данных
 * @vue-data {number | null} pollsInterval - ид интервала подгрузки списка опросов
 * @vue-data {number | null} resultsInterval - ид интервала обновления результатов
 * @vue-computed {object | null} chainScore - результаты опроса
 * @vue-computed {Array} polls - список опросов
 * @vue-computed {object} currentPresentationInfo - информация о текущей презентации
 * @vue-computed {number} activePollId - запущенный опрос
 * @vue-computed {number} activeResultsId - запущенные результаты опросы
 * @vue-computed {number} activeTestId - запущенный тест
 * @vue-computed {Array} tests - список тестов
 * @vue-computed {boolean} activeAboutWs - запущен ли опрос о венспик
 * @vue-computed {object} [results={}] - результаты с значением по умолчанию
 * @vue-computed {boolean} isTabVoting - текущая вкладка опросы
 * @vue-computed {boolean} isTabTests - текущая вкладка тесты
 * @vue-computed {string} emptyListMessage - текст для списка, если он пуст
 */
export default {
  name: "ViewTestAndPolls",
  components: { Tabs, SpeakerPolls, Loader, SpeakerPollCard },
  data() {
    return {
      tab: "voting",
      isLoading: true,
      pollsInterval: null,
      resultsInterval: null,
    };
  },
  computed: {
    ...mapState(pollsTest, ["chainScore"]),
    ...mapState(speakerPolls, ["polls"]),
    ...mapGetters(room, {
      activePollId: ACTIVE_POLL_ID,
      activeResultsId: ACTIVE_RESULTS_ID,
      activeTestId: ACTIVE_TEST_ID,
    }),
    ...mapGetters(speakerPolls, {
      tests: TESTS,
      activeAboutWs: ACTIVE_ABOUT_WS,
    }),
    results() {
      return this.chainScore || {};
    },
    isTabVoting() {
      return this.tab === "voting";
    },
    isTabTests() {
      return this.tab === "tests";
    },
    emptyListMessage() {
      const localeKeyPart = this.isTabVoting ? "Voting" : "Tests";
      return this.$t(`Tests.empty${localeKeyPart}`);
    },
  },
  watch: {
    /**
     * Запрашиваем тесты при смене вкладки
     *
     * @param {string} val
     */
    tab(val) {
      if (val === "tests") {
        this.getTests();
      }
    },
    /**
     * Следим за состоянием опроса о венспик и сбрасываем тест либо опрос при включении
     * Опрос о венспик имеет высший приоритет
     *
     * @param {boolean} val
     */
    activeAboutWs(val) {
      if (val) {
        if (this.activeResultsId) {
          this.toggleResultsHandler(true, false);
        }
        if (this.activeTestId) {
          this.resetTestId(0);
        }
      }
    },
  },
  async created() {
    await this.init();
  },
  beforeDestroy() {
    clearInterval(this.pollsInterval);
    clearInterval(this.resultsInterval);
  },
  methods: {
    ...mapActions(polls, {
      getResults: SHOW_POLL_SCORE,
    }),
    ...mapActions(speakerPolls, {
      getPolls: GET_POLLS,
      toggleResults: SHOW_QUIZ_RESULT,
      toggleQuiz: TOGGLE_QUIZ,
      getTestsAction: GET_SPEAKER_TEST,
      toggleTest: START_SPEAKER_TEST,
    }),
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    ...mapMutations(polls, {
      resetChainScore: SET_POLLCHAIN_SCORE,
    }),
    ...mapMutations(room, {
      resetTestId: SET_ROOMINFO_TEST_ID,
    }),
    /**
     * Отключаем или включаем показ опроса
     * Так же отключаем тест если запущен
     *
     * @param {boolean} [forcibly=false] - сбрасываем только опросы
     */
    togglePoll(forcibly = false) {
      if (this.activeTestId && !forcibly) {
        this.toggleTestHandler(this.activeTestId);
      }
      let socketObj = { type: "runQuiz", obj: this.results, isShowCounter: false };
      const data = {
        room: this.$route.params.id,
        voting: this.results.id,
      };
      if (this.activePollId) {
        socketObj = {
          type: "closeQuiz",
          obj: { id: 0 },
          id: 0,
        };
        this.toggleQuiz(data);
      } else {
        this.toggleQuiz(data);
      }
      this.setSocketObj(socketObj);
    },
    /**
     * Отключаем или включаем показ результатов
     * Так же отключаем тест если запущен
     *
     * @param {boolean} [forcibly=false] - сбрасываем только результаты
     * @param {boolean} [sendSocket=true] - отправляем сокет сообщение или нет
     */
    toggleResultsHandler(forcibly = false, sendSocket = true) {
      if (this.activeTestId && !forcibly) {
        this.toggleTestHandler(this.activeTestId);
      }
      const data = { obj: {}, type: "showQuizResult" };
      if (!this.activeResultsId) {
        data.obj = {
          count_answers: this.results.count_answers,
          counter: this.results.counter,
          id: this.results.id,
          options: this.results.options,
          timer: this.results.timer,
          title: this.results.title,
          trade_schedule: this.results.trade_schedule,
        };
      } else {
        data.obj = {
          event: "hide",
        };
      }

      if (sendSocket) {
        this.setSocketObj(data);
      }
      this.toggleResults(data);
    },
    /**
     * Отключаем или включаем показ теста
     * Так же если запущены результаты или опрос то отключаем их
     *
     * @param {number} id - ид теста
     */
    toggleTestHandler(id) {
      // Закрытие окна с опросом
      if (this.activePollId) {
        this.togglePoll(true);
      }

      // Закрытие окна с результатами.
      if (this.activeResultsId) {
        this.toggleResultsHandler(true);
      }

      // Метод включения/отключения теста
      this.toggleTest(id).then(res => {
        // Параметр конца теста
        const { isStarted } = res;
        const socketObj = {
          type: "currentTest",
          obj: {
            currentTest: id, // Было id !== this.activeTestId ? id : 0, посылали 0 чтобы в CURRENT_TEST по 0 закрыть активный тест/опрос
            isStarted,
          },
        };

        // Вызываем метод CURRENT_TEST (src/store/modules/socket/modules/event/event.js) у пользователей
        // рассылая сокет "currentTest".
        this.setSocketObj(socketObj);
      });
    },
    /**
     * Инциализация страницы и выставление интервала обновления списка
     * Запрос результатов если опрос запущен
     */
    async init() {
      try {
        const polls = await this.getPolls({
          pres: +this.$route.params.presId,
        });

        if ((!!this.activePollId || !!this.activeResultsId) && !this.activeAboutWs) {
          const poll = polls.find(
            poll => poll.id === this.activePollId || poll.id === this.activeResultsId,
          );
          if (poll) {
            await this.getResultsHandler(poll);
          }
        }
        this.pollsInterval = setInterval(() => {
          this.getPolls({
            pres: +this.$route.params.presId,
          });
        }, LOAD_POLLS_INTERVAL_MS);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Запрашиваем результаты выбраного опроса
     *
     * @param {object} poll - опрос
     * @returns {Promise<void>}
     */
    async getResultsHandler(poll) {
      clearInterval(this.resultsInterval);
      const request = {
        type: test,
        payload: poll,
      };

      await this.getResults(request);
      this.resultsInterval = setInterval(() => {
        this.getResults(request);
      }, LOAD_POLLS_INTERVAL_MS);
    },
    /**
     * Обработка нажатия "К списку" на мобильных версиях
     * Сбрасываем объект результатов
     */
    backHandler() {
      clearInterval(this.resultsInterval);
      this.resetChainScore({
        type: test,
        payload: null,
      });
    },
    /**
     * Запрашиваем список тестов.
     *
     * @returns {Promise<void>}
     */
    async getTests() {
      try {
        this.isLoading = true;
        await this.getTestsAction(this.$route.params.presId);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  max-width: $laptop;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: $gray-lightest;

  .content {
    flex: 1;
    order: 1;
    overflow: hidden;

    &__empty {
      width: 100%;
      height: 100%;
      text-align: center;

      @include mq-tablet {
        font-size: 24px;
      }
    }
  }

  .tabs {
    order: 2;
  }

  @include mq-tablet-l {
    padding: 15px;
    background: transparent;

    .content {
      order: 2;
    }

    .tabs {
      order: 1;
    }
  }
}

.tests {
  flex: 1;
  width: 100%;
  padding: 0 10px 20px;
  margin-top: 20px;
  overflow: auto;

  @include reset-scroll;

  &__item:not(:last-of-type) {
    margin-bottom: 10px;

    @include mq-tablet-l {
      margin-bottom: 15px;
    }
  }

  @include mq-tablet-l {
    padding: 0 0 20px;
  }
}

.group {
  padding: 10px;
}
.groupName {
  padding: 5px;
}
</style>
