<template>
  <div
    :class="[
      $style.wrapper,
      {
        [$style.wrapper_running]: running,
        [$style.wrapper_test]: test,
      },
    ]"
    class="flex flex-column"
    @click="select"
  >
    <span :class="$style.title">{{ item.title }}</span>
    <div :class="$style.info" class="text-md flex align-center">
      <div v-if="activePollId && running && $mqToLaptop && !test">
        <speaker-poll-timer />
      </div>
      <span v-show="hasAnswers && !(activePollId && running)" :class="$style.info__ended">
        {{ $t("Tests.stopped") }}
      </span>

      <span v-show="hasAnswers" :class="$style.info__counter">
        <ws-icon light>user-friends</ws-icon>
        {{ answersCount }}
      </span>

      <template v-if="$mqToLaptop || test">
        <ws-button
          v-if="(activePollId && running) || test"
          :color="test && !running ? 'primary' : 'danger'"
          :class="$style.info__btnPoll"
          :disabled="test && activeAboutWs"
          data-testid="pollBtn"
          @click.stop="pollBtnHandler"
        >
          <ws-icon>{{ test && !running ? "play" : "times" }}</ws-icon>
          {{ pollBtnText }}
        </ws-button>
        <ws-button
          v-if="activeResultsId && running"
          :class="$style.info__btnResults"
          data-testid="resultsBtn"
          @click.stop="$emit('stop-results')"
        >
          <ws-icon>eye-slash</ws-icon>
          {{ hideResultsBtnText }}
        </ws-button>
      </template>

      <a
        v-if="isAvailableDownloadReport"
        :href="item.pdfLink"
        target="_blank"
        :class="$style.info__pdfLink"
        data-testid="pdfLink"
      >
        <ws-icon class="text-sm">download</ws-icon>
        {{ $t("Tests.downloadResult") }}
      </a>
      <span v-if="isOtherPollRunning" :class="$style.info__error" data-testid="error">
        {{ $t("Tests.errorStopPrevPoll") }}
      </span>
    </div>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import SpeakerPollTimer from "@/components/speaker/testsAndPolls/SpeakerPollTimer";

/**
 * Карточка теста или опроса
 * @vue-prop {Boolean} [selected=false] - выбран текущий опрос или нет
 * @vue-prop {Object} item - элемент списка опроса для отображенния данных
 * @vue-prop {Boolean} [availableDownloadReport=false] - включен ли модуль загрузки отчетов pdf
 * @vue-prop {Number} [activePollId=0] - id запущенного опроса
 * @vue-prop {Number} [activeResultsId=0] - id запущенного результата опроса
 * @vue-prop {Number} [activeAboutWs=false] - запущен ли опрос о венспик в данный момент
 * @vue-prop {Boolean} [test=false] - является ли карточка тестом
 * @vue-computed {Boolean} hasAnswers - есть ли ответы на вопросы
 * @vue-computed {Boolean} isOtherPollRunning - запущен другой опрос или результаты опроса
 * @vue-computed {Boolean} running - запушен текущий опрос или нет
 * @vue-computed {String} hideResultsBtnText - текст кнопки скрыть релузьтаты(укороченный, либо нет)
 * @vue-computed {String} pollBtnText - текст кнопки запустить опрос/тест
 * @vue-event {Object} select - выбор для отображения результатов по опросу
 * @vue-event stop-poll - останавливаем запущенный опрос
 * @vue-event stop-results - останавливаем запущенные результаты
 * @vue-event toggle-test - запускаем/останавливаем тест
 */
export default {
  name: "SpeakerPollCard",
  components: {
    WsButton,
    WsIcon,
    SpeakerPollTimer,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    activeResultsId: {
      type: Number,
      default: 0,
    },
    activePollId: {
      type: Number,
      default: 0,
    },
    activeAboutWs: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    answersCount() {
      return this.test ? this.item.users_passed : this.item.count_answers;
    },
    hasAnswers() {
      return this.answersCount > 0;
    },
    running() {
      return this.item.id === this.activePollId || this.item.id === this.activeResultsId;
    },
    isOtherPollRunning() {
      return this.selected && !this.running && (this.activePollId || this.activeResultsId);
    },
    isAvailableDownloadReport() {
      return !this.test && !this.running && this.item.availableDownloadReport;
    },
    hideResultsBtnText() {
      return this.$mqLaptop ? this.$t("Tests.hideResults") : this.$t("common.hide");
    },
    pollBtnText() {
      if (this.test) {
        return this.running ? this.$t("Tests.stopTest") : this.$t("Tests.startTest");
      }
      return this.$t("Tests.stopPoll");
    },
  },
  methods: {
    /**
     * Обработка клика на карточку
     */
    select() {
      this.$emit("select", this.item);
    },
    /**
     * Запуска/остановка теста или остановка опроса
     */
    pollBtnHandler() {
      if (this.test) {
        this.$emit("toggle-test");
      } else {
        this.$emit("stop-poll");
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  $shadow: 0 2px 15px rgba($black, 0.15);

  width: 100%;
  padding: 15px;
  cursor: pointer;
  background: $white;
  border-radius: 4px;
  box-shadow: $shadow;
  transition: box-shadow 0.3s linear;

  @include mq-tablet-l {
    border: 1px solid $gray-lighten;
    box-shadow: none;

    &:hover {
      box-shadow: $shadow;
    }
  }

  &_running {
    border: 2px solid var(--base-color);
  }
}

.title {
  max-width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  word-break: break-word;
}

.info {
  flex-wrap: wrap;

  &__ended {
    margin-right: 15px;
    color: $blue;
  }

  &__pdfLink {
    color: var(--base-color);

    &:hover {
      opacity: 0.8;
    }
  }

  &__counter {
    margin-right: 15px;
    margin-left: auto;
  }

  &__error {
    width: 100%;
    margin-top: 5px;
    color: $danger;
  }

  &__btnResults {
    color: $white;

    @include scssToCssVariable(
      (
        button-bg-color: $blue,
        button-bg-active-color: $blue-darken,
      )
    );
  }

  @include mq-tablet-l {
    &__pdfLink {
      margin-left: auto;
    }

    &__counter {
      margin: 0;
    }
  }
}

.wrapper_test {
  .info {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .info__btnPoll {
    margin-right: auto;
  }

  .info__counter {
    margin-right: 15px;
  }

  .info__ended {
    margin-right: 0;
  }
}
</style>
