<template>
  <div class="flex flex-column">
    <div
      v-for="(item, key) in answers"
      :key="item.variantId || key"
      :class="$style.bar"
      class="flex align-center justify-between"
    >
      <div :class="$style.bar__index">
        <span :class="$style.bar__indexText">
          {{ convertIndexToLetter(key) }}
        </span>
      </div>

      <div :class="$style.bar__progress">
        <div v-line-clamp:18="1" :class="$style.bar__title">
          {{ item.variantName }}
        </div>
        <div
          :class="$style.bar__fill"
          :style="{
            width: item.countAnswers + '%',
            backgroundColor: colors[key],
          }"
        ></div>
      </div>

      <div :class="$style.bar__percents">{{ roundValue(item.countAnswers) }} %</div>
    </div>
  </div>
</template>
<script>
import useBarChartHelpers from "@/components/speaker/testsAndPolls/mixins/useBarChartHelpers";

/**
 * Горизонтальное отображение результатов опроса
 */
export default {
  name: "SpeakerResultsHorizontalBar",
  mixins: [useBarChartHelpers],
};
</script>

<style lang="scss" module>
.bar {
  margin-bottom: 18px;

  &__index {
    margin-right: 15px;
  }

  &__indexText {
    display: block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border: 1px solid $gray-dark;
    border-radius: 50%;
  }

  &__progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 28px;
    padding: 0 10px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
  }

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 36px;
  }

  &__title {
    position: relative;
    z-index: 1;
    font-size: 15px;
  }

  &__percents {
    min-width: 49px;
    max-width: 49px;
    margin-left: 15px;
    color: $gray-dark;
    text-align: end;
    white-space: nowrap;
  }
}
</style>
