<template>
  <div :class="$style.results" class="flex flex-column">
    <div :class="$style.results__header" class="flex justify-between">
      <ws-button
        v-if="$mqToLaptop"
        :text="!$mqToMobileM"
        :icon="$mqToMobileM"
        color="primary"
        data-testid="backBtn"
        @click="$emit('back')"
      >
        <ws-icon :lg="$mqToMobileM">arrow-left</ws-icon>
        <span v-if="!$mqToMobileM">{{ $t("Tests.backToList") }}</span>
      </ws-button>
      <ws-button
        :color="activePollId && !activeAboutWs ? 'danger' : 'primary'"
        :class="$style.results__btnPoll"
        :disabled="!!activeResultsId || activeAboutWs"
        data-testid="pollBtn"
        @click="$emit('toggle-poll')"
      >
        <ws-icon>{{ activePollId && !activeAboutWs ? "times" : "play" }}</ws-icon>
        {{ activePollId && !activeAboutWs ? $t("Tests.stopPoll") : $t("Tests.startPoll") }}
      </ws-button>
      <ws-button
        v-if="isResultsBtnVisible"
        :class="$style.results__btnResults"
        color="default"
        :disabled="!!activePollId || activeAboutWs"
        data-testid="resultsBtn"
        @click="$emit('toggle-results')"
      >
        <ws-icon>{{ activeResultsId ? "eye-slash" : "eye" }}</ws-icon>
        {{ activeResultsId ? this.$t("common.hide") : this.$t("Tests.results") }}
      </ws-button>
    </div>
    <div :class="$style.results__content" class="flex flex-column">
      <span :class="[$style.results__title, $style.results__mainTitle]">{{ results.title }}</span>

      <div
        v-for="(question, index) in resultsQuestions"
        :key="`question_key_${index}`"
        class="flex flex-column"
        :class="$style.results__item"
      >
        <span
          v-if="resultsQuestions.length >= 1 || results.title !== question.question"
          :class="$style.results__title"
        >
          {{ question.name || question.question }}
        </span>
        <template v-if="showBars(index)">
          <speaker-horizontal-bar
            v-if="
              question.props.diagram_type.for_closed === 'horizontal' ||
                ($mqToLaptop && question.props.diagram_type.for_closed === 'vertical')
            "
            :answers="question.answers"
          />
          <speaker-histogram-bar
            v-if="question.props.diagram_type.for_closed === 'vertical' && !$mqToLaptop"
            :answers="question.answers"
          />
        </template>
      </div>
      <span :class="$style.results__count">
        <ws-icon light>user-friends</ws-icon>
        {{ results.user_count }}
      </span>
    </div>
  </div>
</template>
<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import SpeakerHorizontalBar from "@/components/speaker/testsAndPolls/SpeakerResultsHorizontalBar";
import SpeakerHistogramBar from "@/components/speaker/testsAndPolls/SpeakerResultsHistogramBar";

const NORESULTS_POLL_TYPES = ["choose-wrong", "relation", "relation-images", ""];

/**
 * Результаты опроса
 *
 * @vue-prop {object} [results={}] - результаты опроса
 * @vue-prop {number} [activePollId=0] - id запущенного опроса
 * @vue-prop {number} [activeResultsId=0] - id запущенного результата опроса
 * @vue-prop {number} [activeAboutWs=false] - запущен ли опрос о венспик в данный момент
 * @vue-computed {Array} [resultsQuestions=[]] - вопросы для результатов
 * @vue-computed {boolean} isResultsBtnVisible - отображать ли кнопку показать результаты
 * @vue-event toggle-poll - включение/отключение показа опроса
 * @vue-event toggle-results - включение/отключение показа результатов опроса
 */
export default {
  name: "SpeakerPollResults",
  components: { WsButton, WsIcon, SpeakerHistogramBar, SpeakerHorizontalBar },
  props: {
    activePollId: {
      type: Number,
      default: 0,
    },
    activeResultsId: {
      type: Number,
      default: 0,
    },
    activeAboutWs: {
      type: Boolean,
      default: false,
    },
    results: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    resultsQuestions() {
      return this.results.questions || [];
    },
    isResultsBtnVisible() {
      return (
        !!this.results.user_count &&
        this.resultsQuestions.length &&
        this.resultsQuestions.some(question => {
          const type = question.poll_type || question.poll?.poll_type;
          return !NORESULTS_POLL_TYPES.includes(type);
        })
      );
    },
  },
  methods: {
    /**
     * Определяет отображать ли ответы пользователей
     * TODO: Временное решение, которое скрывает неподготовленные вопросы для отображения.
     *
     * @param {number} index - Позиция варианта в результатах (resultsQuestions)
     * @returns {boolean} - Отображать ли ответы пользователей у этого вопроса.
     */
    showBars(index) {
      const allowedPolls = [
        "open_answer",
        "one_answer",
        // TODO: Сделать показ результатов для закомментированных ниже типов вопроса.
        // "question_correlation",
        // "choose_wrong",
        "several_answers",
        "analog_scale",
      ];
      return (
        allowedPolls.includes(this.resultsQuestions[index].poll_type) ||
        allowedPolls.includes(this.resultsQuestions[index].props.poll_type)
      );
    },
  },
};
</script>
<style lang="scss" module>
.results {
  height: 100%;
  overflow: hidden;

  @include mq-tablet-l {
    $header-height: 95px;

    position: absolute;
    top: 20px;
    right: 0;
    width: calc(50% - 30px);
    height: auto;
    max-height: calc(100vh - #{$navigation-height} - #{$header-height});
    border: 1px solid $gray-lighten;
    border-radius: 4px;

    &__btnPoll {
      order: 1;
      margin-left: auto;
    }
  }

  &__header {
    z-index: 1;
    flex-shrink: 0;
    width: 100%;
    padding: 15px;
    background: $white;
    box-shadow: $box-shadow-common;

    @include mq-tablet-l {
      border-radius: 4px 4px 0 0;
    }
  }

  &__btnResults {
    color: $white;

    @include scssToCssVariable(
      (
        button-bg-color: $blue,
        button-bg-active-color: $blue-darken,
      )
    );

    @include mq-tablet-l {
      order: 0;
    }
  }

  &__content {
    flex: 1;
    padding: 20px 15px 10px;
    overflow: auto;
    background: $white;

    @include mq-tablet-l {
      background: transparent;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
    hyphens: auto;
    word-break: break-word;
  }

  &__mainTitle {
    margin-bottom: 30px;
  }

  &__item {
    flex: 1 0 auto;
    padding-top: 20px;
    border-top: 1px solid $gray-lighten;
  }

  &__count {
    width: 100%;
    padding: 20px 0 0;
    font-weight: 500;
    text-align: center;
  }
}
</style>
