<template>
  <div :class="$style.tabs" class="flex">
    <label
      :class="[
        $style.tabs__item,
        {
          [$style.tabs__item_active]: value === 'voting',
        },
      ]"
      class="flex flex-center"
    >
      {{ $t("Tests.votes") }}
      <input
        class="visually-hidden"
        type="radio"
        name="tab"
        value="voting"
        @input="$emit('input', $event.target.value)"
      />
    </label>
    <label
      :class="[
        $style.tabs__item,
        {
          [$style.tabs__item_active]: value === 'tests',
        },
      ]"
      class="flex flex-center"
    >
      {{ $t("Tests.testGroup") }}
      <input
        class="visually-hidden"
        type="radio"
        name="tab"
        value="tests"
        @input="$emit('input', $event.target.value)"
      />
    </label>
  </div>
</template>
<script>
/**
 * Навигация между списками опросов и тестов
 * @vue-prop {String} value - voting | tests, выбранная вкладка для отображения списка
 * @vue-event {String} input - выбор вкладки
 */
export default {
  name: "SpeakerTestAndPollsTabs",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.tabs {
  display: flex;
  padding: 10px 15px;
  background: $white;
  box-shadow: 0 0 15px rgba($black, 0.15);

  @include mq-tablet-l {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  &__item {
    width: 50%;
    height: 40px;
    font-size: 16px;
    color: var(--base-color);
    cursor: pointer;
    border-color: $gray-lighten;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    border-radius: 4px 0 0 4px;
    transition: all 150ms linear;

    &:last-of-type {
      border-width: 1px 1px 1px 0;
      border-radius: 0 4px 4px 0;
    }

    @include mq-tablet-l {
      max-width: 190px;
    }

    &:hover {
      border-color: var(--base-color);
    }
  }

  &__item_active {
    color: $white;
    background: var(--base-color);
    border-color: var(--base-color);

    &:hover {
      background: var(--ws-color-primary--active);
      border-color: var(--ws-color-primary--active);
    }
  }
}
</style>
