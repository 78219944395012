import createAlphabeticalIndex from "@/utils/createAlphabeticIndex";

/**
 * Миксин для отрисовки диаграмм в результатах
 *
 * @vue-prop {Array} answers - ответы на вопросы
 * @vue-data {Array} colors - цвета для отрисовки диаграмм ответов
 */
export default {
  props: {
    answers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colors: [
        "#88c92d",
        "#9bd0fe",
        "#6beac7",
        "#62cff7",
        "#c7f05f",
        "#88c92d",
        "#9bd0fe",
        "#6beac7",
        "#62cff7",
        "#c7f05f",
        "#88c92d",
        "#9bd0fe",
        "#6beac7",
        "#62cff7",
        "#c7f05f",
        "#88c92d",
        "#9bd0fe",
        "#6beac7",
        "#62cff7",
        "#c7f05f",
      ],
    };
  },
  methods: {
    /**
     * Выдает букву из алфавита по порядковому номеру
     * Счет начинается с 0
     *
     * @param {number} index
     * @returns {string} - буква английского алфавита
     */
    convertIndexToLetter(index) {
      return createAlphabeticalIndex(index);
    },
    /**
     * Округляет значение числа
     *
     * @param value
     * @returns {string|*}
     */
    roundValue(value) {
      if (Number.isInteger(value)) {
        return value;
      }
      return value.toFixed(1);
    },
  },
};
