<template>
  <div :class="$style.wrapper" class="flex flex-column">
    <div :class="$style.chart" class="flex justify-between">
      <div :class="$style.chart__bg">
        <div
          v-for="percent in stepsOfPercents"
          :key="percent"
          :class="[$style['percent-' + percent.replace('%', '')], $style.chart__line]"
        >
          <span :class="$style.chart__percentValue">{{ percent }}</span>
        </div>
      </div>

      <div v-for="(item, key) in answers" :key="item.variantId || key" :class="$style.bar">
        <div :class="$style.bar__progress">
          <div
            :class="$style.bar__fill"
            :style="{
              height: item.countAnswers + '%',
              backgroundColor: colors[key],
            }"
          ></div>
        </div>

        <div :class="$style.bar__title">{{ convertIndexToLetter(key) }}</div>
      </div>
    </div>

    <div :class="$style.barRows" class="flex flex-column">
      <div
        v-for="(item, key) in answers"
        :key="`${item.variantId}_${key}`"
        :class="$style.barRows__bar"
      >
        <div :class="$style.barRows__index">{{ convertIndexToLetter(key) }}</div>
        <div :class="$style.barRows__color" :style="{ backgroundColor: colors[key] }"></div>
        <div v-line-clamp="2" :class="$style.barRows__text" :title="item.variantName">
          {{ item.variantName }}
        </div>
        <div :class="$style.barRows__percents">{{ roundValue(item.countAnswers) }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import useBarChartHelpers from "@/components/speaker/testsAndPolls/mixins/useBarChartHelpers";

/**
 * Отображение результатов вертикально
 * @vue-data {Array} stepsOfPercents - шаги для отображения процентов в графике
 */
export default {
  name: "SpeakerResultsHorizontalBar",
  mixins: [useBarChartHelpers],
  data() {
    return {
      stepsOfPercents: ["100%", "80", "60", "40", "20", "0"],
    };
  },
};
</script>

<style lang="scss" module>
.wrapper {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.chart {
  position: relative;
  align-items: stretch;
  padding: 0 35px;
  margin-bottom: 20px;

  &__bg {
    position: absolute;
    top: 0;
    left: -15px;
    width: calc(100% + 30px);
    height: calc(100% - 29px);
  }

  &__line {
    position: absolute;
    left: 0;
    width: 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &__percentValue {
    position: absolute;
    top: 0;
    left: 16px;
    font-size: 13px;
    color: #999;
    transform: translateY(-50%);
  }
}

.bar {
  position: relative;
  display: flex;
  flex: 1;
  flex-basis: 70px;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 70px;
  height: 100%;
  margin: 0 2px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &__progress {
    position: relative;
    width: 100%;
    min-height: 200px;
  }

  &__fill {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  &__title {
    position: relative;
    left: 50%;
    margin-top: 10px;
    font-size: 13px;
    color: $gray-dark;
    text-align: center;
    transform: translateX(-50%);
  }
}

.barRows {
  &__bar {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;

    > * {
      flex-grow: 1;
    }
  }

  &__index {
    flex-grow: 0;
    min-width: 11px;
    max-width: 11px;
    margin-right: 9px;
    font-size: 13px;
    color: $gray-dark;
  }

  &__color {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    max-height: 16px;
    margin-right: 15px;
    border-radius: 3px;
  }

  &__text {
    margin-right: 10px;
  }

  &__percents {
    flex-grow: 0;
  }
}

$percents: (
  "100": 100%,
  "80": 80%,
  "60": 60%,
  "40": 40%,
  "20": 20%,
  "0": 0,
);

@each $key, $value in $percents {
  .percent-#{$key} {
    bottom: $value;
  }
}
</style>
