<template>
  <div :class="$style.container" class="flex flex-column">
    <div v-show="($mqToLaptop && !selectedPollId) || $mqLaptop" :class="$style.polls">
      <speaker-poll-card
        v-for="poll in polls"
        :key="poll.id"
        :item="poll"
        :class="$style.polls__item"
        :active-poll-id="activePollId"
        :active-results-id="activeResultsId"
        :selected="selectedPollId === poll.id || backupSelectedPollId === poll.id"
        :data-testid="`pollCard${poll.id}`"
        :active-about-ws="activeAboutWs"
        @select="setPollId"
        @stop-poll="$emit('toggle-poll')"
        @stop-results="$emit('toggle-results')"
      />
    </div>
    <speaker-poll-results
      v-show="isResultsVisible"
      :active-poll-id="activePollId"
      :active-results-id="activeResultsId"
      :active-about-ws="activeAboutWs"
      :results="results"
      data-testid="pollResults"
      @back="setPollId({ id: 0 })"
      @toggle-poll="$emit('toggle-poll')"
      @toggle-results="$emit('toggle-results')"
    />
  </div>
</template>

<script>
import SpeakerPollCard from "@/components/speaker/testsAndPolls/SpeakerPollCard";
import SpeakerPollResults from "@/components/speaker/testsAndPolls/SpeakerPollResults";

/**
 * Вкладка опросов
 *
 * @vue-prop {Array} [polls=[]] - список опросов
 * @vue-prop {object} [results={}] - результаты опроса
 * @vue-prop {number} [activePollId=0] - id запущенного опроса
 * @vue-prop {number} [activeResultsId=0] - id запущенного результата опроса
 * @vue-data {number} selectedPollId - ид выбраного опроса
 * @vue-prop {number} [activeAboutWs=false] - запущен ли опрос о венспик в данный момент
 * @vue-data {number} backupSelectedPollId - для мобильного вида чтобы определять запущен другой опрос или нет
 * @vue-computed {boolean} isResultsVisible - показать карточку результатов
 * @vue-event {object} get-results - запросить результаты для выбраного опроса
 * @vue-event toggle-poll - включение/отключение показа опроса
 * @vue-event toggle-results - включение/отключение показа результатов опроса
 * @vue-event back - для мобильной версии при возврате назад к списку сбрасываем результаты
 */
export default {
  name: "SpeakerPolls",
  components: {
    SpeakerPollResults,
    SpeakerPollCard,
  },
  props: {
    polls: {
      type: Array,
      default: () => [],
    },
    results: {
      type: Object,
      default: () => ({}),
    },
    activePollId: {
      type: Number,
      default: 0,
    },
    activeResultsId: {
      type: Number,
      default: 0,
    },
    activeAboutWs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPollId: 0,
      backupSelectedPollId: 0,
    };
  },
  computed: {
    isRunning() {
      return (!!this.activePollId || !!this.activeResultsId) && !this.activeAboutWs;
    },
    isResultsVisible() {
      return this.selectedPollId || (this.isRunning && this.$mqLaptop);
    },
  },
  watch: {
    isResultsVisible(val) {
      if (val && !this.results.id) {
        const poll = this.polls.find(
          poll => poll.id === this.activePollId || poll.id === this.activeResultsId,
        );
        if (poll) {
          this.$emit("get-results", poll);
        }
      }
    },
  },
  mounted() {
    this.selectedPollId = this.results.id || 0;
  },
  methods: {
    /**
     * Выбираем карточку для отображения результатов
     * Кейсы:
     * Мобильный вид - возврат назад без сброса всего состояния если опрос запущен
     * Мобильный вид - пропуск логики установки selectedPollId если опрос запущен и выбранный не является запущенным
     * Сбросить состояние если опрос не запущен по клику на "К списку"
     * Запросить результаты если опрос не запущен
     *
     * @param {object} poll - опрос для запроса результатов
     */
    setPollId(poll) {
      if (this.$mqToLaptop) {
        this.backupSelectedPollId = poll.id;
      }

      if (poll.id === 0) {
        this.selectedPollId = 0;
        if (!this.isRunning) {
          this.$emit("back");
        }
        return;
      }

      const isPollRunning = poll.id === this.activePollId || poll.id === this.activeResultsId;

      if (this.$mqToLaptop && this.isRunning && !isPollRunning) return;

      this.selectedPollId = poll.id;

      if (!this.isRunning) {
        this.$emit("get-results", poll);
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.polls {
  flex: 1;
  width: 100%;
  padding: 0 10px 20px;
  margin-top: 20px;
  overflow: auto;

  @include reset-scroll;

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 10px;

      @include mq-tablet-l {
        margin-bottom: 15px;
      }
    }

    @include mq-tablet-l {
      max-width: 50%;
      margin-right: auto;
    }
  }

  @include mq-tablet-l {
    padding: 0 0 20px;
  }
}
</style>
