<template>
  <div class="timer">
    <span>
      {{ time }}
    </span>
  </div>
</template>

<script>
import EasyTimer from "easytimer";

export default {
  name: "SpeakerTime",
  data() {
    return {
      time: null,
      timer: null,
    };
  },
  computed: {
    initTime() {
      this.time.start();
      return this.time.getTimeValues().toString();
    },
  },
  created() {
    this.timer = new EasyTimer();
    this.timer.start();
    this.timer.addEventListener("secondsUpdated", e => {
      const value = this.timer.getTimeValues().toString();
      this.time = value;
    });
  },
};
</script>

<style scoped lang="less">
.timer {
  width: 63px;
  font-weight: 500;
  color: #00a3ff;

  span {
    font-family: inherit;
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .timer {
    width: 90px;
    height: 26px;
    padding: 0 12px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    background: #00a3ff;
    border-radius: 24px;
  }
}
</style>
